import {KeyboardEventHandler, useCallback} from "react";
import {useNavigate} from "react-router-dom";

import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";
import {getVehiclePictureByType} from "@fleet/common/utils/vehicle/getVehiclePictureByType";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {VehicleMarketplaceNoAuthService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {Chip, Spinner, Typography} from "@bolteu/kalep-react";

import BodyType = VehicleMarketplaceService.CarModelBodyType;

export interface TableListing extends Omit<VehicleMarketplaceNoAuthService.Listing, "status"> {
    is_user_applied: boolean;
}

export interface ListingsTableBodyProps {
    listings: TableListing[];
    isLoading: boolean;
}

const ListingTile = (props: {listing: TableListing; className?: string}) => {
    const {i18n} = useI18n();
    const {listing, className = ""} = props;
    const navigate = useNavigate();
    const {getListingPath} = useAbsolutePath();

    const onClickListing = useCallback(() => {
        if (listing.id) {
            navigate(getListingPath(listing.id));
        }
    }, [listing.id, navigate, getListingPath]);

    const onKeyDownListing = useCallback<KeyboardEventHandler<HTMLDivElement>>(
        (event) => {
            if (event.key === "Enter" || event.key === " ") {
                navigate(getListingPath(listing.id));
            }
        },
        [listing.id, navigate, getListingPath],
    );

    const offerPeriod = listing.offer_period ? i18n(`listings.offer_period.${listing.offer_period}`) : "";
    return (
        <div
            className={`${className} cursor-pointer pb-4`}
            onClick={onClickListing}
            onKeyDown={onKeyDownListing}
            role="button"
            tabIndex={0}
        >
            <div className="flex justify-center p-8">
                <img
                    src={listing.image_url ?? getVehiclePictureByType(BodyType.SEDAN)}
                    alt="Vehicle"
                    className="h-56 w-full rounded-md object-cover"
                />
            </div>
            <div className="mx-8">
                <Typography variant="body-m-accent" fontWeight="semibold">
                    {`${listing.offer_price}${listing.offer_price_currency}/${offerPeriod}`}
                </Typography>
            </div>
            <div className="mx-8 flex justify-between pt-2">
                <div className="text-secondary flex items-end text-sm">
                    {`${listing.vehicle_manufacturer} ${listing.vehicle_model_name}, ${listing.vehicle_years}`}
                </div>
                {listing.is_user_applied && <Chip label={i18n("listings.is_applied")} size="sm" appearance="neutral" />}
            </div>
        </div>
    );
};

const ListingsTableBody = (props: ListingsTableBodyProps) => {
    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";
    const isLargeOrBigger = viewport !== "sm" && viewport !== "md";

    const {isLoading, listings} = props;
    if (isLoading) {
        return (
            <div className="flex items-center justify-center">
                <Spinner size={500} />
            </div>
        );
    }

    if (isMobileView) {
        return (
            <div className="divide-separator flex flex-col divide-y divide-solid">
                {listings.map((x) => (
                    <ListingTile listing={x} />
                ))}
            </div>
        );
    }

    const numOfCols = isLargeOrBigger ? 3 : 2;

    return (
        <div className="grid border-collapse grid-cols-2 lg:grid-cols-3">
            {listings.map((x, i) => {
                const rightBorder = (i + 1) % numOfCols !== 0 ? "border-r" : "";
                const borderBottom = i + 1 <= listings.length - (listings.length % numOfCols) ? "border-b" : "";
                return (
                    <ListingTile
                        key={x.id}
                        className={`border-separator border-solid ${rightBorder} ${borderBottom}`}
                        listing={x}
                    />
                );
            })}
        </div>
    );
};

export default ListingsTableBody;
