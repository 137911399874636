import {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {useI18n} from "common/hooks/useI18n";
import {ApplicationContextProvider} from "common/providers/appStateProvider";
import {LocalStorageKeys, localStorageService} from "common/services/LocalStorageService";
import {getHomePathByCity} from "common/util/getHomePathByCity";
import config from "config/index";
import {filter, minBy} from "lodash-es";
import {Status, useIpLocationData} from "@fleet/common/hooks/useIpLocationData";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {VehicleMarketplaceNoAuthService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {SelectOption, Typography} from "@bolteu/kalep-react";

import ListingsTable from "./components/ListingsTable";
import styles from "./style.module.css";

const ListingsPage = () => {
    const {i18n} = useI18n();
    const appState = useContext(ApplicationContextProvider);
    const params = useParams();
    const locationData = useIpLocationData(
        config.geoServiceUrl,
        localStorageService.getItemKey(LocalStorageKeys.IP_LOCATION),
    );
    const navigate = useNavigate();
    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    const [selectedCountry, setSelectedCountry] = useState<VehicleMarketplaceNoAuthService.Country | null>(null);
    const [selectedCity, setSelectedCity] = useState<SelectOption | null>(null);

    useEffect(() => {
        if (appState.cities !== null) {
            const currentCity = appState.cities.find((c) => c.name === params.city);
            if (currentCity) {
                setSelectedCountry(appState.countries?.find((c) => c.code === currentCity.country) ?? null);
                setSelectedCity({value: currentCity.id, title: currentCity.name});
                if (!appState.isAppReady) {
                    appState.setIsAppReady(true);
                }
            } else {
                if (locationData === Status.LOADING) {
                    return;
                }
                // find the smallest id city in the same country. Smaller id is expected to be the default city
                const nextBestCity = minBy(
                    filter(appState.cities, (c) => c.country === locationData?.country_iso_code),
                    "id",
                );
                if (nextBestCity) {
                    navigate(getHomePathByCity(nextBestCity.name));
                } else {
                    window.location.href = config.driverPortalUrl;
                }
            }
        }
    }, [params.city, appState, locationData, navigate]);

    const updateSelectedCity = useCallback((city: SelectOption) => {
        window.location.href = getHomePathByCity(typeof city.title === "string" ? city.title : "");
    }, []);

    return (
        <>
            <div className="flex w-full flex-col justify-end gap-7 px-16 pt-10">
                <p className={isMobileView ? styles.headerMobile : styles.header}>{i18n("banner.title_v2")}</p>
                <Typography paddingBottom={3}>{i18n("banner.description")}</Typography>
                <div className="-ml-16 w-screen bg-[#0C2C1C] py-16 px-6 md:px-16">
                    <span className="text-3xl font-semibold text-white">
                        {i18n("banner.weekly_driving", {
                            earnUpTo: (
                                <span className="text-action-secondary">
                                    {i18n("banner.earn_up_to", {
                                        amount: selectedCountry?.weekly_earnings_estimate ?? "",
                                    })}
                                </span>
                            ),
                        })}
                    </span>
                </div>
            </div>
            <div className="bg-white">
                <ListingsTable selectedCity={selectedCity} setSelectedCity={updateSelectedCity} />
            </div>
        </>
    );
};

export default ListingsPage;
