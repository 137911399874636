import {AppBrand} from "@fleet/common/constants/appConstants";

const version = import.meta.env.PACKAGE_VERSION as string;
const [major, minor] = (version || "ERR.ERR").split(".");

const config = {
    appName: "VehicleMarketplace",
    baseUrl: `${import.meta.env.VITE_API_URL_SCHEME}://${import.meta.env.VITE_API_URL_SUFFIX}`,
    version: `VM.${major}.${Number(minor) < 10 ? `0${minor}` : minor}`,
    versionNumber: `${version}`,
    appStage: import.meta.env.VITE_STAGE as string,
    isStageLive: import.meta.env.VITE_STAGE === "live",
    isEnvLocal: import.meta.env.DEV,
    appBrand: (import.meta.env.VITE_BRAND as string).toLowerCase() as AppBrand,
    defaultLocale: "en-us",
    crowdinFolder: "Web/Vehicle Marketplace",
    crowdinBackendProject: "vehicle-marketplace-client",
    crowdinFileName: "translations.json",
    mixpanelToken: import.meta.env.VITE_MIXPANEL_TOKEN as string,
    sentryDataSourceName: import.meta.env.VITE_SENTRY_DATA_SOURCE_NAME as string,
    geoServiceUrl: import.meta.env.VITE_GEO_SERVICE_URL as string,
    accessTokenRefreshTimeoutBeforeExpiryInSeconds: 60,
    driverPortalUrl: import.meta.env.VITE_DRIVER_PORTAL_URL as string,
};

export type ConfigType = typeof config;
export default config;
