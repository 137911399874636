import React, {useContext, useEffect, useState} from "react";

import {FetchStatus, useFetchNoAuth} from "common/hooks/useFetch";
import {VehicleMarketplaceNoAuthApiClient} from "common/services/api/api_clients/noAuthApiClient";

import {VehicleMarketplaceNoAuthService} from "@bolteu/bolt-server-api-vehicle-marketplace";

import {ThirdPartyContextProvider} from "./appThirdPartyProvider";

import Country = VehicleMarketplaceNoAuthService.Country;
import City = VehicleMarketplaceNoAuthService.City;

export interface ApplicationState {
    isAppReady: boolean;
    setIsAppReady: (isError: boolean) => void;
    refreshToken: string | null;
    setRefreshToken: (token: string | null) => void;
    isAuthenticated: boolean;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    isAuthCompleted: boolean;
    setIsAuthCompleted: (isAuthenticated: boolean) => void;
    cities: City[] | null;
    countries: Country[] | null;
}

const ApplicationContextProvider = React.createContext<ApplicationState>({
    isAppReady: false,
    setIsAppReady: () => {},
    refreshToken: null,
    setRefreshToken: () => {},
    isAuthenticated: false,
    setIsAuthenticated: () => {},
    isAuthCompleted: false,
    setIsAuthCompleted: () => {},
    cities: null,
    countries: null,
});
ApplicationContextProvider.displayName = "ApplicationContextProvider";

const fetchCitiesFunction = () => VehicleMarketplaceNoAuthApiClient.webGetAvailableCities();
const ApplicationProvider = ({children}: {children: React.ReactNode}) => {
    const {observability} = useContext(ThirdPartyContextProvider);

    const [isAppReady, setIsAppReady] = useState<boolean>(false);
    const [refreshToken, setRefreshToken] = useState<string | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isAuthCompleted, setIsAuthCompleted] = useState<boolean>(false);

    const [availableCountries, setAvailableCountries] = useState<Country[] | null>(null);
    const [availableCities, setAvailableCities] = useState<City[] | null>(null);
    const {fetch: fetchCities, data: citiesData, status: fetchCityStatus} = useFetchNoAuth(fetchCitiesFunction);

    useEffect(() => {
        if (fetchCities) {
            fetchCities({});
        }
    }, [fetchCities]);

    useEffect(() => {
        if (fetchCityStatus === FetchStatus.Success) {
            const countries: Country[] = [];
            const cities: City[] = [];
            citiesData.countries?.forEach((country) => {
                countries.push(country);
                cities.push(...country.cities);
            });
            setAvailableCities(cities.sort((a, b) => a.id - b.id));
            setAvailableCountries(countries);
        }
    }, [citiesData?.countries, fetchCityStatus]);

    useEffect(() => {
        observability.addBreadcrumb("app ready", {isAppReady});
    }, [observability, isAppReady]);

    useEffect(() => {
        observability.addBreadcrumb("user is authenticated", {isAuthenticated});
    }, [observability, isAuthenticated]);

    return (
        <ApplicationContextProvider.Provider
            value={{
                isAppReady,
                setIsAppReady,
                refreshToken,
                setRefreshToken,
                isAuthenticated,
                setIsAuthenticated,
                isAuthCompleted,
                setIsAuthCompleted,
                cities: availableCities,
                countries: availableCountries,
            }}
        >
            {children}
        </ApplicationContextProvider.Provider>
    );
};

export {ApplicationContextProvider, ApplicationProvider};
