import {useContext, useEffect} from "react";
import {Navigate, To, useLocation, useParams} from "react-router-dom";

import {ApplicationContextProvider} from "common/providers/appStateProvider";
import {LocalStorageKeys, localStorageService} from "common/services/LocalStorageService";
import {getHomePathByCity} from "common/util/getHomePathByCity";
import config from "config/index";
import {FullScreenLoader} from "@fleet/common/components/FullScreenLoader";
import {Status, useIpLocationData} from "@fleet/common/hooks/useIpLocationData";

export const Redirect = () => {
    const {isAppReady, setIsAppReady, cities} = useContext(ApplicationContextProvider);
    const location = useLocation();
    const locationData = useIpLocationData(
        config.geoServiceUrl,
        localStorageService.getItemKey(LocalStorageKeys.IP_LOCATION),
    );

    const params = useParams();
    useEffect(() => {
        if (!isAppReady && locationData && locationData !== Status.LOADING && cities !== null) {
            setIsAppReady(true);
        }
    }, [isAppReady, setIsAppReady, locationData, cities]);

    let navigateTo: To | null = null;
    let city = params.city || (locationData !== Status.LOADING && locationData?.city);
    if (city) {
        navigateTo = {pathname: getHomePathByCity(city), search: location.search};
    } else if (locationData !== Status.LOADING) {
        city = (cities || []).find((c) => c.country === locationData?.country_iso_code)?.name;

        if (city) {
            navigateTo = {pathname: getHomePathByCity(city), search: location.search};
        }
    }

    if (!isAppReady) {
        return <FullScreenLoader size={700} />;
    }

    if (!navigateTo) {
        window.location.href = config.driverPortalUrl;
        return <></>;
    }

    return <Navigate to={navigateTo} replace />;
};
