import {useCallback, useEffect} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/api/apiProvider";
import {getVehicleColorClasses} from "@fleet/common/utils/vehicle/vehicleColorUtil";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";
import {Chip, Typography} from "@bolteu/kalep-react";

import {isAlertShown} from "../hooks/useApplicationAlert";
import {ApplicationAlert} from "./ApplicationAlert";
import {ImageCarousel} from "./ImageCarousel";
import {ListingCards} from "./ListingCards";

interface Props {
    data: VehicleMarketplaceService.GetListingPartnerResponse;
    requestVehicleClicked: () => void;
    applicationDeactivated: () => void;
}

const deactivateRequestFunction = (api: Api, body: VehicleMarketplaceService.DeactivateApplicationRequest) =>
    api.vehicleMarketplace.partnerDriverWebVehicleApplicationDeactivate(body);

export const ListingDetails = ({data, requestVehicleClicked, applicationDeactivated}: Props) => {
    const {fetch: deactivateFetch, status: deactivateFetchStatus} = useFetch(deactivateRequestFunction);

    const {listing_details: listing} = data;
    const {data: listingData, translations: listingTranslations} = listing;

    const onApplicationDeactivated = useCallback(() => {
        if (!deactivateFetch || !data.application_id) {
            return;
        }
        deactivateFetch({application_id: data.application_id});
    }, [data.application_id, deactivateFetch]);

    useEffect(() => {
        if (deactivateFetchStatus === FetchStatus.Success) {
            applicationDeactivated();
        }
    }, [applicationDeactivated, deactivateFetchStatus]);

    const vehicleFields =
        listingTranslations.vehicle_specifics_fields?.values ??
        ({} as Record<string, VehicleMarketplaceService.StringTranslation>);
    const gearbox = vehicleFields.gearbox ?? {key: "", value: ""};
    const mileage = vehicleFields.mileage ?? {key: "", value: ""};
    const fuelType = vehicleFields.fuel_type ?? {key: "", value: ""};

    const title = `${listingData.vehicle_manufacturer} ${listingData.vehicle_model_name}, ${
        listingData.vehicle_years ?? ""
    }`;
    const colorTitles = vehicleFields.vehicle_colors?.value?.split(", ") ?? [];
    const colorValues = listingData.vehicle_colors ?? [];

    const bottomPadding = isAlertShown(data.application_details?.status) ? "pb-6" : "pb-10";

    return (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div className={`flex flex-col gap-4 ${bottomPadding}`}>
                <ImageCarousel images={data.listing_images} />
                <Typography fontSize="text-4xl" fontWeight="semibold">
                    {title}
                </Typography>
                <div className="flex flex-wrap gap-4">
                    {colorValues.map((value, i) => {
                        const colorTitle = colorTitles[i];
                        const colorClasses = getVehicleColorClasses(value);
                        return (
                            <div key={value} className="flex items-center gap-2">
                                <span className={`h-4 w-4 rounded-full ${colorClasses}`} />
                                <span>{colorTitle ?? ""}</span>
                            </div>
                        );
                    })}
                </div>
                <div className="[&>span>span]:text-primary mt-2 flex flex-wrap gap-2 [&>span>span]:overflow-visible">
                    {gearbox.value && <Chip label={gearbox.value} />}
                    {fuelType.value && <Chip label={fuelType.value} />}
                    {mileage.value && <Chip label={`${mileage.key}: ${mileage.value}`} />}
                </div>
            </div>
            <div className="flex flex-col gap-4">
                <ApplicationAlert
                    application={data.application_details}
                    vehicleName={`${listingData.vehicle_model_name} ${listingData.vehicle_years ?? ""}`}
                    onApplicationDeactivated={onApplicationDeactivated}
                    isLoading={deactivateFetchStatus === FetchStatus.Loading}
                />
                <ListingCards data={data} requestVehicleClicked={requestVehicleClicked} />
            </div>
        </div>
    );
};
